import { createSlice, configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

export const formSlice = createSlice({
  name: "form",
  initialState: {
    isOpen: true,
    group: undefined,
    fieldId: undefined,
    units: "imperial",
    endDate: null,
    pdfLoading: false,
  },
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    setFieldId: (state, action) => {
      state.fieldId = action.payload;
    },
    setUnits: (state, action) => {
      state.units = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setPdfLoading: (state, action) => {
      state.pdfLoading = action.payload;
    },
  },
});

export const vizSlice = createSlice({
  name: "viz",
  initialState: {
    fieldLevelMoisture: null,
    precipitation: null,
    plantingWindow: null,
    riskMaps: null,
    saturationRisk: null,
    sufficiencyRisk: null,
    seedZoneMoisture: null,
    waterAvailable: null,
  },
  reducers: {
    setFieldLevelMoisture: (state, action) => {
      state.fieldLevelMoisture = action.payload;
    },
    setPrecipitation: (state, action) => {
      state.precipitation = action.payload;
    },
    setPlantingWindow: (state, action) => {
      state.plantingWindow = action.payload;
    },
    setSaturationRisk: (state, action) => {
      state.saturationRisk = action.payload;
    },
    setRiskMaps: (state, action) => {
      state.riskMaps = action.payload;
    },
    setSufficiencyRisk: (state, action) => {
      state.sufficiencyRisk = action.payload;
    },
    setSeedZoneMoisture: (state, action) => {
      state.seedZoneMoisture = action.payload;
    },
    setWaterAvailable: (state, action) => {
      state.waterAvailable = action.payload;
    },
  },
});

export default configureStore({
  reducer: {
    form: formSlice.reducer,
    viz: vizSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createLogger()),
});
