import React from "react";
import {
  Button,
  Col,
  Flex,
  Radio,
  Result,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@uidotdev/usehooks";

import { EndDateDatePicker } from "./EndDatePicker.jsx";
import { Loading } from "./loading.jsx";
import { onPdf } from "./pdf.jsx";
import { formSlice } from "./store.js";
import * as viz from "./viz";
import { vizThunk } from "./thunks/viz.js";
import "./analytics.css";

function withLoader(VizComponent, data) {
  let element = null;
  if (data === "") {
    element = (
      <Row justify="center">
        <Spin />
      </Row>
    );
  } else if (data === null) {
    element = (
      <Row justify="center">
        <Result status="warning" subTitle="Data not found" />
      </Row>
    );
  } else if (data) {
    element = (
      <div key={VizComponent.className}>
        <VizComponent data={data} />
        <br />
      </div>
    );
  }
  return (
    <Row
      style={{
        padding: 10,
        margin: 10,
        border: "1px solid #f0f0f0",
      }}
    >
      <Col xs={22} offset={1}>
        {element}
      </Col>
    </Row>
  );
}

function Analytics() {
  const vizData = useSelector((state) => state.viz);
  const dispatch = useDispatch();
  const { setPdfLoading, setUnits } = formSlice.actions;
  const { units } = useSelector((state) => state.form);
  const [fieldId, setFieldId] = React.useState(null);
  const [fieldIds, setFieldIds] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("planting-conditions");
  const { groupId } = useParams();
  const mobile = useMediaQuery("only screen and (max-width : 925px)");

  React.useEffect(() => {
    if (groupId && !fieldId) {
      const _fetch = async () => {
        const response = await fetch(`/data/fields/${groupId}`);
        const data = await response.json();
        setFieldIds(data);
      };
      _fetch();
    }
    if (groupId && fieldId) {
      dispatch(vizThunk(groupId, fieldId));
    }
  }, [dispatch, fieldId, groupId]);

  const empties = Object.values(vizData).filter((value) => !value).length;
  const analyticsHeader = () => {
    if (mobile) {
      return (
        <Row justify="center" style={{ marginTop: 60 }} gutter={2}>
          <Col xs={12} />
          <Col xs={12}>
            <Select
              placeholder="Select Field"
              onSelect={(value) => setFieldId(value)}
              options={fieldIds?.map((key) => ({
                label: key.name,
                value: key,
              }))}
            />
          </Col>
          <Col xs={12}>
            <Radio.Group
              value={units}
              onChange={(e) => dispatch(setUnits(e.target.value))}
            >
              <Radio.Button value="imperial">Imperial</Radio.Button>
              <Radio.Button value="metric">Metric</Radio.Button>
            </Radio.Group>
          </Col>
          <Col xs={12}>
            <EndDateDatePicker style={{ width: "100%" }} />
          </Col>
        </Row>
      );
    }

    return (
      <Flex className={"action-bar"} justify={"space-between"} align={"center"}>
        <Flex
          className={"configs"}
          gap={"4px"}
          justify={"right"}
          align={"center"}
        >
          <Select
            style={{ width: 350 }}
            placeholder="Select Field"
            onSelect={(value) => setFieldId(value)}
            options={fieldIds?.map((key) => ({ label: key.name, value: key }))}
          />
          <Radio.Group
            value={units}
            onChange={(e) => dispatch(setUnits(e.target.value))}
          >
            <Radio.Button value="imperial">Imperial</Radio.Button>
            <Radio.Button value="metric">Metric</Radio.Button>
          </Radio.Group>
          <EndDateDatePicker />
          <Button
            onClick={async () => {
              dispatch(setPdfLoading(true));
              const tabs = ["planting-conditions", "soil-moisture-content"];
              for (const tab of tabs) {
                setActiveTab(tab);
                await new Promise((resolve) => setTimeout(resolve, 1000));
              }
              onPdf(() => dispatch(setPdfLoading(false)));
              setActiveTab("planting-conditions");
            }}
            disabled={empties > 0}
          >
            PDF
          </Button>
        </Flex>
      </Flex>
    );
  };

  const analyticsStyle = mobile
    ? { padding: "0 10px 0 10px", marginTop: 70 }
    : { padding: "16px 24px 0 24px" };

  return (
    <div>
      <div style={analyticsStyle}>
        {analyticsHeader()}
        <Tabs
          style={{ width: "96vw", marginTop: 10 }}
          renderTabBar={(props, TabNavList) => (
            <TabNavList {...props} mobile={false} />
          )}
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          items={[
            {
              key: "planting-conditions",
              label: "Planting Conditions",
              children: (
                <>
                  {withLoader(viz.PrecipitationBar, vizData.precipitation)}
                  {withLoader(
                    viz.PlantingWindowCategory,
                    vizData.plantingWindow
                  )}
                  {withLoader(viz.RiskMaps, vizData.riskMaps)}
                  {withLoader(
                    viz.SaturationRiskTimeSeries,
                    vizData.saturationRisk
                  )}
                  {withLoader(
                    viz.SufficiencyRiskTimeSeries,
                    vizData.sufficiencyRisk
                  )}
                </>
              ),
            },
            {
              key: "soil-moisture-content",
              label: "Soil Moisture Content",
              children: (
                <>
                  {withLoader(viz.PrecipitationBar, vizData.precipitation)}
                  {withLoader(
                    viz.PlantingWindowCategory,
                    vizData.plantingWindow
                  )}
                  {withLoader(
                    viz.SeedZoneMoistureRanges,
                    vizData.seedZoneMoisture
                  )}
                  {withLoader(
                    viz.WaterAvailableSurface,
                    vizData.waterAvailable
                  )}
                  {withLoader(
                    viz.FieldLevelMoistureRanges,
                    vizData.fieldLevelMoisture
                  )}
                </>
              ),
            },
          ]}
        />
      </div>
      <Loading />
    </div>
  );
}

export default Analytics;
